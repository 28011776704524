$enable-shadows: true;

$yellow: #F8E9A1;
$pink: #F76C6C;
$info: #A8D0E6;
$blue: #374785;
$dark: #24305E;
$darkgray: #343a40;
$lightgray: #f8f9fa;

@import 'media-queries';
@import '~bootstrap/scss/bootstrap';

@import 'footer';
@import 'forms';
@import 'menus';
@import 'navbar';
@import 'overlays';
@import 'search-bar';
@import 'message';
@import 'tables';

@import 'frontpage';
@import 'page';


body {
    .featured-number {
        color: $dark;
    }

    a {
        color: $blue;
    }

}