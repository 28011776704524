.p-autocomplete {
    width: 100%;

    input {
        width: 100%;

        &::-webkit-input-placeholder {
            text-align: center;
         }
         
         &:-moz-placeholder { /* Firefox 18- */
            text-align: center;  
         }
         
         &::-moz-placeholder {  /* Firefox 19+ */
            text-align: center;  
         }
         
         &:-ms-input-placeholder {  
            text-align: center; 
         }
    }

   .p-highlight {
      background-color: $info!important;

      .item-name, svg {
         color: $darkgray;
      }
   }

   @include respond-below(md) {
      position: fixed;
      top: -1rem!important;
      left: 30px;
      width: calc(100% - 60px - 32px);
      opacity: 0;
      z-index: 99;
      transition: all .5s ease-in-out;

      &.show {
         top: 1rem;
         opacity: 1;
      }
   }
}

@include respond-below(lg) {
   .sticky {
      .p-autocomplete {
         top: .5rem;
      }

      .hide-search {
         top: .5rem;
      }
   }
}

.hide-search {
   position: fixed;
   z-index: 99;
   top: -1rem;
   right: 30px;
   color: white;
   background: none !important;
   border: none !important;
   height: 35px;
   padding: 0;
   opacity: 0;
   transition: all .5s ease-in-out;

   &.show {
      opacity: 1;
      top: 1rem;
   }
}